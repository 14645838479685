import axios from '@/common/axios'
import qs from 'qs'

export function addProjectProposal (data) {
  return axios({
    method: 'post',
    url: '/universal/project/add',
    data: qs.stringify(data)
  })
}

export function deleteProjectProposal (id) {
  return axios({
    method: 'delete',
    url: '/universal/project/delete/' + id
  })
}

export function updateProjectProposal (data) {
  return axios({
    method: 'put',
    url: '/universal/project/update',
    data: qs.stringify(data)
  })
}

export function selectProjectProposalInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/project/info/' + id
  })
}

export function selectProjectProposalList (query) {
  return axios({
    method: 'get',
    url: '/universal/project/list',
    params: query
  })
}
