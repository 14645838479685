<template>
  <div id="projectProposal">
    <el-dialog
      :title="projectProposalFormTitle"
      width="1200px"
      :visible.sync="projectProposalDialogVisible"
      :close-on-click-modal="false"
      @close="projectProposalDialogClose"
    >
      <el-form
        ref="projectProposalFormRef"
        :model="projectProposalForm"
        :rules="projectProposalFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目提议人" prop="proposer">
              <el-input v-model="projectProposalForm.proposer" placeholder="请输入项目提议人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="部门" prop="dept">
              <el-input v-model="projectProposalForm.dept" placeholder="请输入部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目类型" prop="projectType">
              <el-input v-model="projectProposalForm.projectType" placeholder="请输入项目类型" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="proposeDate">
              <el-date-picker v-model="projectProposalForm.proposeDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="projectProposalForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="projectProposalForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="适用范围" prop="scope">
              <el-input v-model="projectProposalForm.scope" placeholder="请输入适用范围" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品性能" prop="performance">
              <el-input v-model="projectProposalForm.performance" placeholder="请输入产品性能" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申报类别" prop="declarationCategory">
              <el-input v-model="projectProposalForm.declarationCategory" placeholder="请输入申报类别" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="研发型式" prop="rdType">
              <el-radio-group v-model="projectProposalForm.rdType">
                <el-radio :label="1">
                  自主研发
                </el-radio>
                <el-radio :label="2">
                  合作研发
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合作单位" prop="unit">
              <el-input v-model="projectProposalForm.unit" placeholder="请输入合作单位" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="同类上市企业数" prop="companyNumber">
              <el-input v-model="projectProposalForm.companyNumber" placeholder="请输入同类上市企业数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销售量" prop="salesVolume">
              <el-input v-model="projectProposalForm.salesVolume" placeholder="请输入销售量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前零售价" prop="retailPrice">
              <el-input v-model="projectProposalForm.retailPrice" placeholder="请输入当前零售价" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前中标价" prop="bidPrice">
              <el-input v-model="projectProposalForm.bidPrice" placeholder="请输入当前中标价" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="前三年零售价" prop="threeYearsRetailPrice">
              <el-input v-model="projectProposalForm.threeYearsRetailPrice" placeholder="请输入前三年零售价" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="前三年中标价" prop="threeYearsBidPrice">
              <el-input v-model="projectProposalForm.threeYearsBidPrice" placeholder="请输入前三年中标价" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="国外上市销售情况（或国内同类产品销售情况）" prop="foreignSales" label-width="360px">
              <el-input v-model="projectProposalForm.foreignSales" placeholder="请输入国外上市销售情况（或国内同类产品销售情况）" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上市背景" prop="listingBackground">
              <el-input v-model="projectProposalForm.listingBackground" placeholder="请输入上市背景" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="作用机制" prop="mechanism">
              <el-input v-model="projectProposalForm.mechanism" placeholder="请输入作用机制" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="安全性" prop="safety">
              <el-input v-model="projectProposalForm.safety" placeholder="请输入安全性" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申报情况" prop="reportingSituation">
              <el-input v-model="projectProposalForm.reportingSituation" placeholder="请输入申报情况" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他需补充说明" prop="otherNotes">
              <el-input v-model="projectProposalForm.otherNotes" placeholder="请输入其他需补充说明" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="专家意见" prop="expertAdvice">
              <el-input
                v-model="projectProposalForm.expertAdvice"
                placeholder="请输入专家意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="信息来源" prop="expertInfoSource">
              <el-input v-model="projectProposalForm.expertInfoSource" placeholder="请输入信息来源" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="确认人" prop="expertConfirmor">
              <el-input v-model="projectProposalForm.expertConfirmor" placeholder="请输入确认人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="临床专家意见" prop="clinicalExpertOpinion">
              <el-input
                v-model="projectProposalForm.clinicalExpertOpinion"
                placeholder="请输入临床专家意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="信息来源" prop="clinicalExpertInfoSource">
              <el-input v-model="projectProposalForm.clinicalExpertInfoSource" placeholder="请输入信息来源" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="确认人" prop="clinicalExpertConfirmor">
              <el-input v-model="projectProposalForm.clinicalExpertConfirmor" placeholder="请输入确认人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审评专家意见" prop="reviewExpertOpinion">
              <el-input
                v-model="projectProposalForm.reviewExpertOpinion"
                placeholder="请输入审评专家意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="信息来源" prop="reviewExpertInfoSource">
              <el-input v-model="projectProposalForm.reviewExpertInfoSource" placeholder="请输入信息来源" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="确认人" prop="reviewExpertConfirmor">
              <el-input v-model="projectProposalForm.reviewExpertConfirmor" placeholder="请输入确认人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目领导委员会预审意见" prop="projectLeaderOpinion">
              <el-input
                v-model="projectProposalForm.projectLeaderOpinion"
                placeholder="请输入项目领导委员会预审意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总经理签批" prop="generalManagerApproval">
              <el-input
                v-model="projectProposalForm.generalManagerApproval"
                placeholder="请输入总经理签批"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="projectProposalDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="projectProposalFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="项目提议人">
        <el-input v-model="searchForm.proposer" placeholder="请输入项目提议人" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="projectProposalPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="proposer" label="项目提议人" />
      <el-table-column prop="dept" label="部门" />
      <el-table-column prop="projectType" label="项目类型" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.proposeDate">{{ scope.row.proposeDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="scope" label="适用范围" />
      <el-table-column prop="performance" label="产品性能" />
      <el-table-column prop="declarationCategory" label="申报类别" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="projectProposalPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addProjectProposal, deleteProjectProposal, updateProjectProposal, selectProjectProposalInfo, selectProjectProposalList } from '@/api/universal/projectProposal'

export default {
  data () {
    return {
      projectProposalDialogVisible: false,
      projectProposalFormTitle: '',
      projectProposalForm: {
        id: '',
        proposer: '',
        dept: '',
        projectType: '',
        proposeDate: '',
        name: '',
        spec: '',
        scope: '',
        performance: '',
        declarationCategory: '',
        rdType: '',
        unit: '',
        companyNumber: '',
        salesVolume: '',
        retailPrice: '',
        bidPrice: '',
        threeYearsRetailPrice: '',
        threeYearsBidPrice: '',
        foreignSales: '',
        listingBackground: '',
        mechanism: '',
        safety: '',
        reportingSituation: '',
        otherNotes: '',
        expertAdvice: '',
        expertInfoSource: '',
        expertConfirmor: '',
        clinicalExpertOpinion: '',
        clinicalExpertInfoSource: '',
        clinicalExpertConfirmor: '',
        reviewExpertOpinion: '',
        reviewExpertInfoSource: '',
        reviewExpertConfirmor: '',
        projectLeaderOpinion: '',
        generalManagerApproval: ''
      },
      projectProposalFormRules: {
        proposer: [{ required: true, message: '项目提议人不能为空', trigger: ['blur', 'change']}]
      },
      projectProposalPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        proposer: ''
      }
    }
  },
  created () {
    selectProjectProposalList(this.searchForm).then(res => {
      this.projectProposalPage = res
    })
  },
  methods: {
    projectProposalDialogClose () {
      this.$refs.projectProposalFormRef.resetFields()
    },
    projectProposalFormSubmit () {
      if (this.projectProposalFormTitle === '项目建议书详情') {
        this.projectProposalDialogVisible = false
        return
      }
      this.$refs.projectProposalFormRef.validate(async valid => {
        if (valid) {
          if (this.projectProposalFormTitle === '新增项目建议书') {
            await addProjectProposal(this.projectProposalForm)
          } else if (this.projectProposalFormTitle === '修改项目建议书') {
            await updateProjectProposal(this.projectProposalForm)
          }
          this.projectProposalPage = await selectProjectProposalList(this.searchForm)
          this.projectProposalDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.projectProposalFormTitle = '新增项目建议书'
      this.projectProposalDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteProjectProposal(row.id)
        if (this.projectProposalPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.projectProposalPage = await selectProjectProposalList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.projectProposalFormTitle = '修改项目建议书'
      this.projectProposalDialogVisible = true
      this.selectProjectProposalInfoById(row.id)
    },
    handleInfo (index, row) {
      this.projectProposalFormTitle = '项目建议书详情'
      this.projectProposalDialogVisible = true
      this.selectProjectProposalInfoById(row.id)
    },
    selectProjectProposalInfoById (id) {
      selectProjectProposalInfo(id).then(res => {
        this.projectProposalForm.id = res.id
        this.projectProposalForm.proposer = res.proposer
        this.projectProposalForm.dept = res.dept
        this.projectProposalForm.projectType = res.projectType
        this.projectProposalForm.proposeDate = res.proposeDate
        this.projectProposalForm.name = res.name
        this.projectProposalForm.spec = res.spec
        this.projectProposalForm.scope = res.scope
        this.projectProposalForm.performance = res.performance
        this.projectProposalForm.declarationCategory = res.declarationCategory
        this.projectProposalForm.rdType = res.rdType
        this.projectProposalForm.unit = res.unit
        this.projectProposalForm.companyNumber = res.companyNumber
        this.projectProposalForm.salesVolume = res.salesVolume
        this.projectProposalForm.retailPrice = res.retailPrice
        this.projectProposalForm.bidPrice = res.bidPrice
        this.projectProposalForm.threeYearsRetailPrice = res.threeYearsRetailPrice
        this.projectProposalForm.threeYearsBidPrice = res.threeYearsBidPrice
        this.projectProposalForm.foreignSales = res.foreignSales
        this.projectProposalForm.listingBackground = res.listingBackground
        this.projectProposalForm.mechanism = res.mechanism
        this.projectProposalForm.safety = res.safety
        this.projectProposalForm.reportingSituation = res.reportingSituation
        this.projectProposalForm.otherNotes = res.otherNotes
        this.projectProposalForm.expertAdvice = res.expertAdvice
        this.projectProposalForm.expertInfoSource = res.expertInfoSource
        this.projectProposalForm.expertConfirmor = res.expertConfirmor
        this.projectProposalForm.clinicalExpertOpinion = res.clinicalExpertOpinion
        this.projectProposalForm.clinicalExpertInfoSource = res.clinicalExpertInfoSource
        this.projectProposalForm.clinicalExpertConfirmor = res.clinicalExpertConfirmor
        this.projectProposalForm.reviewExpertOpinion = res.reviewExpertOpinion
        this.projectProposalForm.reviewExpertInfoSource = res.reviewExpertInfoSource
        this.projectProposalForm.reviewExpertConfirmor = res.reviewExpertConfirmor
        this.projectProposalForm.projectLeaderOpinion = res.projectLeaderOpinion
        this.projectProposalForm.generalManagerApproval = res.generalManagerApproval
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectProjectProposalList(this.searchForm).then(res => {
        this.projectProposalPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectProjectProposalList(this.searchForm).then(res => {
        this.projectProposalPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectProjectProposalList(this.searchForm).then(res => {
        this.projectProposalPage = res
      })
    }
  }
}
</script>

<style>
</style>
